import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import AgendaSession from '../AgendaSession/AgendaSession';
import AgendaStreamLogo from '../../../../shared/AgendaStreamLogo/AgendaStreamLogo';

import './AgendaStream.scss';

export default function AgendaStream(props) {
  let { sessionsInGroup = [], currentView, clickSessionHandler } = props;

  sessionsInGroup = sessionsInGroup.filter((stream) => {
    return (
      stream.streamItems.filter((item) => item.type !== 'space' && item.enabled)
        .length > 0
    );
  });

  return (
    sessionsInGroup.length > 0 &&
    sessionsInGroup.map((item, key) => {
      return (
        <Fragment key={key}>
          {
            <div
              className={`agenda-stream-name stream-color stream-border-color ${currentView}`}
              data-stream-color-index={item.color}
            >
              {item.name}
              {item.logo && (
                <AgendaStreamLogo logo={item.logo} logoName={item.name} />
              )}
            </div>
          }
          {item.streamItems.map((i, k) => {
            if (i.enabled && i.type !== 'space') {
              return (
                <AgendaSession
                  streamId={item.streamId}
                  key={k}
                  session={i}
                  currentView={currentView}
                  clickSessionHandler={(sessionIndex) =>
                    clickSessionHandler(sessionIndex)
                  }
                />
              );
            }

            return null;
          })}
        </Fragment>
      );
    })
  );
}

AgendaStream.propTypes = {
  sessionsInGroup: PropTypes.array,
  currentView: PropTypes.string,
  clickSessionHandler: PropTypes.func,
};
