import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withSessionsIndexMap } from '../../context';
import { renderHTMLString, throttle } from '../../../../../helpers';
import { getSessionIndexId } from '../../../../../utils/getSessions';

import AgendaSpeakers from '../../AgendaCommonSections/AgendaSpeakers/AgendaSpeakers';
import AgendaTimeStatus from '../../../../shared/AgendaTimeStatus/AgendaTimeStatus';

import './AgendaSession.scss';
import AgendaCustomLabel from '../../components/AgendaCustomLabel';

const STATIC_ITEM_HEIGHT = 55;

export class AgendaSession extends Component {
  constructor(props) {
    super(props);
    const { content: { duration, relativeHeightOnGridView, type } = {} } =
      props;

    this.mainItemRef = React.createRef();

    this.state = {
      itemTruncated: false,
      itemHeight: this.getItemHeight(),
      specificItemClass:
        type === 'session' && relativeHeightOnGridView && duration < 10
          ? `shortest-main-agenda-item shortest-main-agenda-item--${duration}`
          : '',
    };

    this.getItemStyles = this.getItemStyles.bind(this);
    this.handleSessionClick = this.handleSessionClick.bind(this);

    this.updateItemDimensions = throttle(this.getItemStyles, 200);
  }

  componentDidMount() {
    this.getItemStyles();
    window.addEventListener('resize', this.updateItemDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateItemDimensions);
  }

  componentDidUpdate() {
    this.updateItemDimensions();
  }

  handleSessionClick() {
    const {
      content: { type, enabled } = {},
      content,
      clickSessionHandler,
      sessionsIndexMap,
    } = this.props;
    const sessionIndex = sessionsIndexMap[getSessionIndexId(content)];

    if (
      type !== 'space' &&
      enabled &&
      typeof clickSessionHandler === 'function' &&
      sessionIndex !== undefined
    ) {
      clickSessionHandler(sessionIndex);
    }
  }

  getItemHeight() {
    const { content: { duration, relativeHeightOnGridView, type } = {} } =
      this.props;
    const scaleFactor = duration < 5 ? 8 : 6;

    return type === 'session' && relativeHeightOnGridView
      ? duration * scaleFactor - 5
      : STATIC_ITEM_HEIGHT;
  }

  getItemStyles() {
    const { content: { type, relativeHeightOnGridView, duration } = {} } =
      this.props;
    const mainItemRef = this.mainItemRef.current;

    if (mainItemRef) {
      const itemContentWrapperHeight = mainItemRef.children[0].clientHeight;

      this.setState((state) => {
        return {
          itemHeight: this.getItemHeight(),
          itemTruncated:
            relativeHeightOnGridView &&
            itemContentWrapperHeight > state.itemHeight,
          specificItemClass:
            type === 'session' &&
            relativeHeightOnGridView &&
            duration < 10 &&
            state.itemTruncated
              ? `shortest-main-agenda-item shortest-main-agenda-item--${duration}`
              : '',
        };
      });
    }
  }

  render() {
    const {
      content: {
        labels = '',
        type,
        title,
        showDescription,
        description = '',
        showSpeakers,
        speakers = '',
        customLabel,
      } = {},
      t,
    } = this.props;

    const {
      itemHeight,
      itemTruncated = false,
      specificItemClass = '',
    } = this.state;
    const isFreeItem = labels.indexOf('Free') >= 0;
    const sessionHeight = type === 'session_break' ? 'auto' : itemHeight;

    return (
      <div
        className={`c-agenda-main-item c-agenda-main-item-${type} ${
          itemTruncated ? 'truncated' : ''
        } ${specificItemClass}`}
        ref={this.mainItemRef}
        style={{ height: sessionHeight }}
        onClick={this.handleSessionClick}
      >
        <div className="c-agenda-stream-item-inner">
          <div className="agenda-item-heading">
            <div className="agenda-item-time">
              <AgendaTimeStatus session={this.props.content} />
              {isFreeItem && (
                <span className="agenda-item-free-label">
                  {t('event.agenda.filter.label.free')}
                </span>
              )}
              {customLabel?.checked && (
                <AgendaCustomLabel labelConfig={customLabel} />
              )}
            </div>
            <div className="agenda-item-title">{renderHTMLString(title)}</div>
          </div>

          {showDescription && description.length > 0 && (
            <div className="row agenda-item-description">
              {renderHTMLString(description)}
            </div>
          )}

          {showSpeakers && speakers.length > 0 && (
            <AgendaSpeakers agendaType="grid" speakers={speakers} />
          )}
        </div>
      </div>
    );
  }
}

AgendaSession.propTypes = {
  sessionsIndexMap: PropTypes.object,
  content: PropTypes.object,
  clickSessionHandler: PropTypes.func,
  t: PropTypes.func,
};

export default withTranslation()(withSessionsIndexMap(AgendaSession));
