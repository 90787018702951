import React from 'react';
import PropTypes from 'prop-types';

import AgendaStream from '../AgendaStream/AgendaStream';

import './AgendaGroup.scss';

export default function AgendaGroup(props) {
  const {
    groupName = '',
    currentView = 'list',
    sessionsInGroup = [],
    clickSessionHandler,
  } = props;

  return (
    <div className="agenda-group">
      {!!groupName.length && (
        <div className={`agenda-group-name ${currentView}`}>{groupName}</div>
      )}
      {!groupName.length && <div className="agenda-group-unnamed" />}
      {!!sessionsInGroup.length && (
        <AgendaStream
          sessionsInGroup={sessionsInGroup}
          currentView={currentView}
          clickSessionHandler={(sessionIndex) =>
            clickSessionHandler(sessionIndex)
          }
        />
      )}
    </div>
  );
}

AgendaGroup.propTypes = {
  groupName: PropTypes.string,
  sessionsInGroup: PropTypes.array,
  currentView: PropTypes.string,
  clickSessionHandler: PropTypes.func,
};
