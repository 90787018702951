import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { getImgixUrl, splitListIntoChunks } from '../../../helpers';
import Utils from '../../../utils';
import './AgendaKeynoteSessions.scss';

export class AgendaKeynoteSessions extends Component {
  static propTypes = {
    agendaItems: PropTypes.array,
    keySessions: PropTypes.array,
    streams: PropTypes.array,
    history: PropTypes.object,
    t: PropTypes.func,
  };

  constructor(props) {
    super(props);

    const { agendaItems, streams } = this.props;
    this.sessions = Utils.getSessions(agendaItems, streams).items;
  }

  getFullName(speakers) {
    return speakers && speakers.length
      ? `${speakers[0].forename} ${speakers[0].surname}`
      : '';
  }

  getCompanyName(speakers) {
    return speakers && speakers.length ? speakers[0].company : '';
  }

  updateLocationHistory(currentId) {
    this.props.history.push(
      `${this.props.history.location.search}#${currentId}`,
    );
  }

  getSessionHash(sessionIndex) {
    const session = this.sessions[sessionIndex];
    return session ? session.itemHash : '';
  }

  render() {
    const { keySessions, t, pageConfig: { tenantId } = {} } = this.props;

    return (
      keySessions &&
      !!keySessions.length && (
        <div className="agenda-keynote-sessions">
          <div className="body-width">
            <div className="agenda-keynote-sessions__title">
              {t('event.agenda.key-sessions')}
            </div>
            {splitListIntoChunks(keySessions, 2).map((row, index) => (
              <div className="row" key={index}>
                {row.map((item, i) => {
                  const backgroundImage = getImgixUrl(
                    tenantId,
                    item.speakers &&
                      item.speakers.length &&
                      item.speakers[0].fullImagePath,
                    'w=88&h=88',
                  );

                  return (
                    <div key={i} className="col-sm-6 col-xs-12">
                      <div
                        className="agenda-keynote-sessions__box"
                        onClick={() => this.updateLocationHistory(item.id)}
                      >
                        <div
                          className="agenda-keynote-sessions__box-image lazy"
                          data-background-image={backgroundImage}
                        />
                        <div className="agenda-keynote-sessions__box-content">
                          <div className="agenda-keynote-sessions__box-content-speaker">
                            {this.getFullName(item.speakers)}
                          </div>
                          <div className="agenda-keynote-sessions__box-content-title">
                            {item.title}
                          </div>
                          <div className="agenda-keynote-sessions__box-content-company">
                            {this.getCompanyName(item.speakers)}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            ))}
          </div>
        </div>
      )
    );
  }
}

function mapStateToProps(state) {
  return {
    pageConfig: state.pageConfig,
  };
}

export default withTranslation()(
  withRouter(connect(mapStateToProps)(AgendaKeynoteSessions)),
);
