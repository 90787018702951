export function getMaxVisibleHeight(sliderContainer) {
  let activeSlides = sliderContainer.querySelectorAll(
    '.slick-active .group-item-wrapper',
  );
  // for some reason the slider identifies next slide from right side
  // after visible slides as in viewport
  // We have to need to recheck it
  activeSlides = [...activeSlides].filter(
    (item) =>
      item.offsetLeft + sliderContainer.offsetLeft <=
      sliderContainer.offsetParent?.offsetWidth,
  );

  const maxHeight = Math.max.apply(
    Math,
    [...activeSlides].map((slide) => slide.clientHeight),
  );

  return maxHeight < 0 ? 0 : maxHeight;
}

export function fixedMagicScroll(dom = document) {
  const pageHeight = dom.querySelector('html').offsetHeight;

  if (window.scrollY + window.innerHeight > pageHeight) {
    setTimeout(() => {
      window.scroll(0, pageHeight);
    }, 150);
  }

  return pageHeight;
}

export function updateGroupHeight(sessionsSlider = this.sessionsSlider) {
  if (!sessionsSlider) {
    return;
  }
  const sessionsSliderListContainer =
    sessionsSlider.innerSlider.list.querySelector('.slick-track');

  sessionsSliderListContainer.style.height = `${getMaxVisibleHeight(
    sessionsSliderListContainer,
  )}px`;

  // fix for magic scroll
  fixedMagicScroll();
}

export function getStickyOffsetTop(dom = document) {
  const mainMenuHeight = dom.querySelector(
    '.c-multi-level-nav--top-nav',
  )?.offsetHeight;
  const agendaNavHeight = dom.getElementsByClassName(
    'c-filterable-content__navigation',
  )[0]?.offsetHeight;

  return -(mainMenuHeight + agendaNavHeight);
}

export function hideFilters(filterIsHidden, sidebarIsHidden, self) {
  if (filterIsHidden !== sidebarIsHidden) {
    self.setState({
      titlesSlider: self.titlesSlider,
      filterIsHidden: sidebarIsHidden,
      stickyStreamTitlesOffsetTop: getStickyOffsetTop(),
    });

    window.scrollBy(0, 1); // to update width of sticked stream titles when sidebar was opened/shown
  }
}

export function checkCurrentSlide(slider) {
  const {
    state: { slideCount, currentSlide },
    props: { slidesToShow },
  } = slider;

  if (slideCount - currentSlide < slidesToShow) {
    slider.slickGoTo(slideCount - slidesToShow);
  }
}

export function observerStickyOffset(observer, dom = document) {
  if (observer) {
    const navigationElement = dom.querySelector(
      '.c-filterable-content__navigation',
    );

    if (navigationElement) {
      observer.observe(navigationElement, { attributes: true });
    }
  }
}
