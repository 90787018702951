import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash/cloneDeep';

// TODO: replace react-sticky lib with react-sticky-el lib
import { Sticky, StickyContainer } from 'react-sticky';
import Slider from '../../../../../modules/react-slick';

import { withTranslation } from 'react-i18next';
import classNames from 'classnames';

import { isBrowser } from '../../../../../helpers';
import AgendaStream from '../AgendaStream/AgendaStream';
import AgendaStreamLogo from '../../../../shared/AgendaStreamLogo/AgendaStreamLogo';
import ArrowButton from '../../ArrowButton/ArrowButton';
import {
  updateGroupHeight,
  getStickyOffsetTop,
  hideFilters,
  checkCurrentSlide,
  observerStickyOffset,
} from '../../../../../utils/agendaGroup';
import './AgendaGroup.scss';
import AgendaCustomLabel from '../../components/AgendaCustomLabel';

const responsive = [
  {
    breakpoint: 319, // previous point
    slidesToShow: 1,
  },
  {
    breakpoint: 767, // previous point
    slidesToShow: 2,
  },
  {
    breakpoint: 1023, // previous point
    slidesToShow: 3,
  },
  {
    breakpoint: 1439, // previous point
    slidesToShow: 4,
  },
  {
    breakpoint: 1599, // previous point
    slidesToShow: 5,
  },
  {
    breakpoint: 2047, // previous point
    slidesToShow: 6,
  },
];

export class AgendaGroup extends Component {
  constructor(props) {
    super(props);

    if (isBrowser()) {
      this.observer = new MutationObserver(this.updateStickyOffset.bind(this));
    }

    this.state = {
      titlesSlider: null,
      filterIsHidden: this.props.sidebarIsHidden,
    };

    this.getStreamsData = this.getStreamsData.bind(this);
    this.updateGroupHeight = updateGroupHeight.bind(this);
    this.updateStickyOffset = this.updateStickyOffset.bind(this);
  }

  componentDidMount() {
    this.setState({
      titlesSlider: this.titlesSlider,
      stickyStreamTitlesOffsetTop: getStickyOffsetTop(),
    });

    observerStickyOffset(this.observer);
  }

  componentWillUnmount() {
    this.observer && this.observer.disconnect();
  }

  componentDidUpdate() {
    const { sidebarIsHidden } = this.props;
    const { filterIsHidden } = this.state;

    hideFilters(filterIsHidden, sidebarIsHidden, this);
    // need to wait when filtering will be finished
    setTimeout(() => this.updateGroupHeight(), 100);

    // if last slides was removed by filter we have to update
    // "currentSlide" property to fill all slider area
    if (this.sessionsSlider) {
      checkCurrentSlide(this.sessionsSlider.innerSlider);
    }
  }

  updateStickyOffset() {
    const stickyOffset = getStickyOffsetTop();
    if (this.state.stickyStreamTitlesOffsetTop !== stickyOffset) {
      this.setState({
        stickyStreamTitlesOffsetTop: stickyOffset,
      });
    }
  }

  getAgendaGroupSettings() {
    const { sidebarIsHidden, content: { streams = [] } = {} } = this.props;
    const filterIsHidden = sidebarIsHidden;
    const streamsAmount = streams.length;
    const getSlidesToShow = (defaultSlidesAmount) => {
      const slidesAmount = filterIsHidden
        ? defaultSlidesAmount
        : defaultSlidesAmount - 1;
      return streamsAmount < slidesAmount ? streamsAmount : slidesAmount;
    };

    return {
      adaptiveHeight: false,
      useTransform: false,
      arrows: streamsAmount > getSlidesToShow(6),
      dots: false,
      fade: false,
      infinite: false,
      initialSlide: 0,
      slidesToShow: getSlidesToShow(6),
      slidesToScroll: 1,
      variableWidth: false,
      swipeToSlide: true,
      focusOnSelect: false,
      prevArrow: <ArrowButton type="preview" />,
      nextArrow: <ArrowButton type="next" />,
      afterChange: () => {
        this.updateGroupHeight();
      },
      responsive: [
        {
          breakpoint: responsive[0].breakpoint,
          settings: {
            arrows: streamsAmount > responsive[0].slidesToShow,
            slidesToShow: responsive[0].slidesToShow,
          },
        },
        {
          breakpoint: responsive[1].breakpoint,
          settings: {
            arrows: streamsAmount > getSlidesToShow(responsive[1].slidesToShow),
            slidesToShow: getSlidesToShow(responsive[1].slidesToShow),
          },
        },
        {
          breakpoint: responsive[2].breakpoint,
          settings: {
            arrows: streamsAmount > getSlidesToShow(responsive[2].slidesToShow),
            slidesToShow: getSlidesToShow(responsive[2].slidesToShow),
          },
        },
        {
          breakpoint: responsive[3].breakpoint,
          settings: {
            arrows: streamsAmount > getSlidesToShow(responsive[3].slidesToShow),
            slidesToShow: getSlidesToShow(responsive[3].slidesToShow),
          },
        },
        {
          breakpoint: responsive[4].breakpoint,
          settings: {
            arrows: streamsAmount > getSlidesToShow(responsive[4].slidesToShow),
            slidesToShow: getSlidesToShow(responsive[4].slidesToShow),
          },
        },
        {
          breakpoint: responsive[5].breakpoint,
          settings: {
            arrows: streamsAmount > getSlidesToShow(responsive[5].slidesToShow),
            slidesToShow: getSlidesToShow(responsive[5].slidesToShow),
          },
        },
      ],
    };
  }

  getStreamsData() {
    const { content, streams } = this.props;
    const streamsDataArray = [];
    const groupStreams = cloneDeep(content.streams);
    const dayStreams = streams;

    const checkOnCrossStreamSession = (streamItem, currentStreamIndex) => {
      let amountOfCrossedStreams = 0;

      const checkInNextStream = (checkingStreamIndex) => {
        const nextStreamItems = groupStreams[checkingStreamIndex + 1]
          ? groupStreams[checkingStreamIndex + 1].streamItems
          : false;
        if (!nextStreamItems) {
          return amountOfCrossedStreams;
        }

        const sameStreamItemInNextStream = nextStreamItems.find((item) => {
          return (
            streamItem.id === item.id && streamItem.startTime === item.startTime
          );
        });

        if (sameStreamItemInNextStream) {
          amountOfCrossedStreams++;

          return checkInNextStream(checkingStreamIndex + 1);
        }

        return amountOfCrossedStreams;
      };
      return checkInNextStream(currentStreamIndex);
    };

    groupStreams.forEach((groupStream, streamIndex) => {
      const streamDetails = dayStreams.find(
        (stream) => stream.id === groupStream.streamId,
      );
      const streamItems = groupStream.streamItems;

      groupStream.name = streamDetails.name;
      groupStream.colorIndex = streamDetails.colorIndex;
      groupStream.isFree = streamDetails.free;

      streamItems.forEach((streamItem) => {
        const isCrossedItem = checkOnCrossStreamSession(
          streamItem,
          streamIndex,
        );
        if (isCrossedItem) streamItem.crossedByStreams = isCrossedItem + 1;
      });

      streamsDataArray.push(groupStream);
    });

    return streamsDataArray;
  }

  render() {
    const { content, t } = this.props;
    const { stickyStreamTitlesOffsetTop, filterIsHidden } = this.state;

    const agendaGroupSettings = this.getAgendaGroupSettings();
    const streamsList = this.getStreamsData();
    const allStreams = content.streams ? content.streams.length : 0;

    const slidesToShowCount = () => {
      const breakpoint = this.sessionsSlider
        ? this.sessionsSlider.state.breakpoint
        : null;
      const activeBreakpoint = responsive.find(
        (e) => e.breakpoint === breakpoint,
      );
      const slidesToShow =
        activeBreakpoint &&
        activeBreakpoint.slidesToShow - (filterIsHidden ? 0 : 1);

      return slidesToShow < allStreams ? slidesToShow : allStreams;
    };

    return (
      <div className="c-agenda-group-item">
        <div className="agenda-group-title-wrapper">
          <div className="agenda-group-title">
            <div className="agenda-group-name">{content.title}</div>
            <div className="agenda-group-amount-description">
              {t('event.agenda.group.streams.info', {
                filteredStreams: slidesToShowCount(),
                streamsAmount: allStreams,
              })}
            </div>
          </div>
        </div>
        <div className="agenda-group-wrapper">
          <StickyContainer>
            <Sticky
              topOffset={stickyStreamTitlesOffsetTop}
              bottomOffset={stickyStreamTitlesOffsetTop}
            >
              {({ style, isSticky }) => (
                <div
                  className={`agenda-stream-titles-wrapper ${
                    isSticky ? 'sticked-titles' : ''
                  }`}
                  style={{
                    ...style,
                    top: isSticky ? -stickyStreamTitlesOffsetTop - 1 + 'px' : 0,
                  }}
                >
                  <Slider
                    asNavFor={this.sessionsSlider}
                    ref={(slider) => (this.titlesSlider = slider)}
                    {...agendaGroupSettings}
                  >
                    {streamsList.map((item, index) => {
                      const streamWrapClass = classNames(
                        'c-agenda-group-item__stream-wrapper',
                        {
                          'c-agenda-group-item__stream-wrapper--logo':
                            item.logo,
                          'c-agenda-group-item__stream-wrapper--no-logo':
                            !item.logo,
                        },
                      );

                      return (
                        <div
                          key={index}
                          data-stream-color-index={item.colorIndex}
                        >
                          <div className="agenda-stream-title stream-color stream-border-color">
                            <div className={streamWrapClass}>
                              <div className="c-agenda-group-item__stream-title">
                                {item.name}
                              </div>
                              {item.isFree && (
                                <span className="agenda-item-free-label c-agenda-group-item__stream-free">
                                  {t('event.agenda.filter.label.free')}
                                </span>
                              )}
                              {item.customLabel?.checked && (
                                <AgendaCustomLabel
                                  labelConfig={item.customLabel}
                                  className="c-agenda-group-item__custom-label"
                                />
                              )}
                              {item.logo && (
                                <AgendaStreamLogo
                                  logo={item.logo}
                                  logoName={item.name}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </Slider>
                </div>
              )}
            </Sticky>
            <Slider
              asNavFor={this.titlesSlider ?? this.state.titlesSlider}
              ref={(slider) => (this.sessionsSlider = slider)}
              {...agendaGroupSettings}
            >
              {streamsList.map((item, index) => {
                return (
                  <div
                    key={index}
                    data-stream-color-index={index}
                    className="group-item-wrapper"
                  >
                    <AgendaStream
                      stream={item}
                      scaleFactor={content.scaleFactor}
                      clickSessionHandler={(sessionId, streamId) =>
                        this.props.clickSessionHandler(sessionId, streamId)
                      }
                      checkGroupHeight={this.updateGroupHeight}
                    />
                  </div>
                );
              })}
            </Slider>
          </StickyContainer>
        </div>
      </div>
    );
  }
}

AgendaGroup.propTypes = {
  content: PropTypes.object,
  streams: PropTypes.array,
  sidebarIsHidden: PropTypes.bool,
  clickSessionHandler: PropTypes.func.isRequired,
  t: PropTypes.func,
};

export default withTranslation()(AgendaGroup);
