import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import getSessions, {
  getSessionIndexId,
  getSessionsIndexMap,
} from '../../../utils/getSessions';
import getFormattedDay from '../../../utils/getFormattedDay';
import { allowScroll, preventScroll } from '../../../helpers';
import { withBrand } from '../../../providers/BrandProvider';
import ModalSessionInfo from '../../shared/ModalSessionInfo/ModalSessionInfo';
import AgendaList from './AgendaList/AgendaList';
import AgendaGrid from './AgendaGrid/AgendaGrid';
import Link from '../../shared/Link/Link';
import './AgendaTable.scss';
import EmailOptInModule from '../../styled/siteBuilderModules/EmailOptInModule';
import { SessionsIndexMapContextProvider } from './context';

export class AgendaTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sessionModalOpened: false,
      keynoteModalOpened: false,
      viewType: this.getViewType(),
    };

    this.extendedSessionsList = props.extendedSessionsList;
    this.closeSessionModal = this.closeSessionModal.bind(this);
    this.changeSlideByHash = this.changeSlideByHash.bind(this);
  }

  componentDidMount() {
    this.checkActiveSessionFromURL(true);
    window.addEventListener('hashchange', this.changeSlideByHash);
  }

  componentWillUnmount() {
    window.removeEventListener('hashchange', this.changeSlideByHash);
  }

  componentDidUpdate() {
    const { items, streams, viewType, sortBy, isLearning } = this.props;

    this.extendedSessionsList = getSessions(items, streams, {
      viewType,
      sortBy,
      isLearning,
    });

    const currentViewType = this.getViewType();

    if (this.state.viewType !== currentViewType) {
      this.setState({
        viewType: currentViewType,
      });
    }
    if (!this.state.sessionModalOpened && !this.state.keynoteModalOpened) {
      this.checkActiveSessionFromURL();
    }
  }

  getViewType() {
    const { currentView, listIsExpanded } = this.props;

    return currentView === 'list' && listIsExpanded
      ? 'expanded_list'
      : currentView;
  }

  changeSlideByHash() {
    this.checkActiveSessionFromURL(true);
  }

  checkActiveSessionFromURL(fromHistory) {
    const { history: { location: { hash = '' } = {} } = {} } = this.props;
    const sessionHash = hash.slice(1);

    if (sessionHash.length) {
      this.showSessionPopupHandler(sessionHash, fromHistory);
    } else if (fromHistory) {
      allowScroll();

      this.setState({
        sessionModalOpened: false,
        keynoteModalOpened: false,
      });
    }
  }

  showSessionPopupHandler(sessionHash) {
    const { keySessions = [], history } = this.props;

    const currentSession = this.getSelectedSession();
    const currentKeySession = keySessions.find(
      (session) => session.id === sessionHash,
    );

    if (currentKeySession?.id === sessionHash) {
      preventScroll();

      this.currentSessionID = currentKeySession.id;

      this.setState((state) => {
        return {
          keynoteModalOpened: !state.keynoteModalOpened,
        };
      });
    } else if (currentSession) {
      preventScroll();

      this.setState((state) => {
        return {
          sessionModalOpened: !state.sessionModalOpened,
        };
      });
    } else {
      history.push(`${history.location.search}#`);
    }
  }

  closeSessionModal() {
    const { history } = this.props;

    allowScroll();

    this.setState(() => {
      return {
        sessionModalOpened: false,
        keynoteModalOpened: false,
      };
    });

    this.currentSessionID = null;

    history.push(`${history.location.search}#`);
  }

  updateLocation(sessionIndex) {
    const { history } = this.props;

    history.push(
      `${history.location.search}#${
        this.extendedSessionsList.items[sessionIndex]?.itemHash || ''
      }`,
    );
  }

  getBottomNavigation() {
    const {
      pageConfig: { sitePath } = {},
      locale,
      agendaDates = [],
      brand,
      currentDayIndex,
      t,
    } = this.props;

    const prevDay = agendaDates[currentDayIndex - 1];
    const nextDay = agendaDates[currentDayIndex + 1];

    return (
      <div className="row agenda-bottom-navigation">
        <div className="col-xs-6">
          {prevDay && (
            <div
              className="prev-day-btn text-left"
              style={{
                backgroundColor: brand.darkSecondaryColor,
                borderRightColor: brand.accentColor,
              }}
            >
              <Link to={`/agenda/${prevDay.dayNumber}`} event={sitePath}>
                <strong
                  className="hidden-xs"
                  style={{ color: brand.accentColor }}
                >
                  {t('event.agenda.nav.prev')}
                </strong>
                <strong
                  className="hidden-sm hidden-md hidden-lg"
                  style={{ color: brand.accentColor }}
                >
                  {t('event.agenda.nav.prev.short')}
                </strong>
                <span className="nav-day-title">{prevDay.name}</span>
                <span className="nav-day-date">
                  {getFormattedDay(prevDay, locale)}
                </span>
              </Link>
            </div>
          )}
        </div>
        <div className="col-xs-6">
          {nextDay && (
            <div
              className="next-day-btn text-right"
              style={{ backgroundColor: brand.secondaryColor }}
            >
              <Link to={`/agenda/${nextDay.dayNumber}`} event={sitePath}>
                <strong style={{ color: brand.accentColor }}>
                  {t('event.agenda.nav.next')}
                </strong>
                <span className="nav-day-title">{nextDay.name}</span>
                <span className="nav-day-date">
                  {getFormattedDay(nextDay, locale)}
                </span>
              </Link>
            </div>
          )}
        </div>
      </div>
    );
  }

  getSelectedSession() {
    const { history: { location: { hash = '' } = {} } = {} } = this.props;
    const sessionHash = hash.slice(1);

    return this.extendedSessionsList?.items?.find((session) => {
      const supportLegacyHashes = session.itemHash
        .split('_')
        .slice(0, session.stream ? 2 : 1)
        .join('_');

      return (
        session.itemHash === sessionHash || supportLegacyHashes === sessionHash
      );
    });
  }

  render() {
    const {
      sortBy = 'time',
      items = [],
      streams = [],
      sidebarIsHidden,
      currentDay,
      type,
      brand = {},
      keySessions,
      isLearning,
      siteHeader: {
        data: {
          emailUpdatesTitle,
          body,
          buttonText,
          confirmationMessage,
          sapProductCode,
        } = {},
      } = {},
    } = this.props;

    const {
      sessionModalOpened,
      viewType = 'grid',
      keynoteModalOpened,
    } = this.state;
    const bottomNavigation = this.getBottomNavigation();
    const isDateAndTimeDisplayed = [undefined, 'Scheduled'].includes(type);
    const sessions = getSessions(items, streams, {
      viewType,
      sortBy,
      isLearning,
    }).items;
    const sessionsIndexMap = getSessionsIndexMap(sessions);
    const selectedSession = this.getSelectedSession();

    return (
      <>
        <SessionsIndexMapContextProvider value={sessionsIndexMap}>
          {viewType === 'grid' ? (
            <AgendaGrid
              items={items}
              streams={streams}
              sidebarIsHidden={sidebarIsHidden}
              clickSessionHandler={(sessionIndex) =>
                this.updateLocation(sessionIndex)
              }
            />
          ) : (
            <AgendaList
              streams={streams}
              items={items}
              currentView={viewType}
              sortBy={sortBy}
              isDateAndTimeDisplayed={isDateAndTimeDisplayed}
              isLearning={isLearning}
              secondaryColor={brand.darkSecondaryColor}
              clickSessionHandler={(sessionIndex) =>
                this.updateLocation(sessionIndex)
              }
            />
          )}
        </SessionsIndexMapContextProvider>

        {bottomNavigation}
        <EmailOptInModule
          section={{
            contentType: 'agenda',
            type: 'event_lead_generation_section',
            title: emailUpdatesTitle,
            body,
            buttonText,
            confirmationMessage,
            sapProductCode,
          }}
          isAgenda={true}
        />
        {sessionModalOpened && (
          <ModalSessionInfo
            sessionIndex={sessionsIndexMap[getSessionIndexId(selectedSession)]}
            currentDay={currentDay}
            sessions={sessions}
            streams={streams}
            brand={brand}
            closeModal={this.closeSessionModal}
            isDateAndTimeDisplayed={isDateAndTimeDisplayed}
            isLearning={isLearning}
          />
        )}
        {keynoteModalOpened && (
          <ModalSessionInfo
            id={this.currentSessionID}
            currentDay={currentDay}
            sessions={keySessions}
            streams={streams}
            brand={brand}
            closeModal={this.closeSessionModal}
            isDateAndTimeDisplayed={isDateAndTimeDisplayed}
            isLearning={isLearning}
          />
        )}
      </>
    );
  }
}

AgendaTable.propTypes = {
  brand: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  currentView: PropTypes.oneOf(['list', 'expanded_list', 'grid']),
  type: PropTypes.string,
  listIsExpanded: PropTypes.bool,
  sortBy: PropTypes.oneOf(['time', 'streams']),
  items: PropTypes.array,
  streams: PropTypes.array,
  sidebarIsHidden: PropTypes.bool,
  agendaDates: PropTypes.array,
  currentDay: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  currentDayIndex: PropTypes.number,
  extendedSessionsList: PropTypes.object,
  pageConfig: PropTypes.object,
  siteHeader: PropTypes.object,
  locale: PropTypes.string,
  t: PropTypes.func,
  keySessions: PropTypes.array,
  isLearning: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    pageConfig: state.pageConfig,
    siteHeader: state.siteHeader,
  };
}

export default withTranslation()(
  connect(mapStateToProps)(withRouter(withBrand(AgendaTable))),
);
