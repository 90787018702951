import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Utils from '../../../../utils';
import AgendaGroup from './AgendaGroup/AgendaGroup';
import AgendaSession from './AgendaSession/AgendaSession';

import './AgendaList.scss';

export default class AgendaList extends Component {
  static propTypes = {
    currentView: PropTypes.string,
    sortBy: PropTypes.string,
    streams: PropTypes.array,
    items: PropTypes.array,
    isDateAndTimeDisplayed: PropTypes.bool,
    isLearning: PropTypes.bool,
    secondaryColor: PropTypes.string,
    clickSessionHandler: PropTypes.func,
  };

  getSessionsByTime(
    content,
    currentView,
    isDateAndTimeDisplayed,
    isLearning,
    secondaryColor,
  ) {
    return (
      content.length > 0 && (
        <div className="c-agenda-list-view sort-by-time">
          {content.map((item, key) => {
            const previousItem =
              !!key && content[key - 1].stream && content[key - 1];
            const previousGroupId = previousItem && previousItem.group.id;

            return (
              <React.Fragment key={key}>
                {((!previousGroupId && item.group) ||
                  (item.group && item.group.id !== previousGroupId)) && (
                  <AgendaGroup groupName={item.group.title} />
                )}
                {(item.enabled || item.enabled === undefined) && (
                  <AgendaSession
                    session={item}
                    currentView={currentView}
                    isDateAndTimeDisplayed={isDateAndTimeDisplayed}
                    isLearning={isLearning}
                    secondaryColor={secondaryColor}
                    clickSessionHandler={(sessionIndex) =>
                      this.props.clickSessionHandler(sessionIndex)
                    }
                  />
                )}
              </React.Fragment>
            );
          })}
        </div>
      )
    );
  }

  getSessionsByStreams(items, currentView) {
    return (
      items.length > 0 && (
        <div className="c-agenda-list-view sort-by-stream">
          {items.map((item, key) => {
            return (
              item.type !== 'space' && (
                <React.Fragment key={key}>
                  {item.type === 'stream_group' && (
                    <AgendaGroup
                      groupName={item.title}
                      sessionsInGroup={item.streams}
                      currentView={currentView}
                      clickSessionHandler={(sessionIndex) =>
                        this.props.clickSessionHandler(sessionIndex)
                      }
                    />
                  )}
                  {item.type !== 'stream_group' && item.enabled && (
                    <AgendaSession
                      session={item}
                      currentView={currentView}
                      clickSessionHandler={(sessionIndex) =>
                        this.props.clickSessionHandler(sessionIndex)
                      }
                    />
                  )}
                </React.Fragment>
              )
            );
          })}
        </div>
      )
    );
  }

  render() {
    const {
      currentView,
      sortBy,
      streams,
      items,
      isDateAndTimeDisplayed,
      isLearning,
      secondaryColor,
    } = this.props;
    const content = Utils.getSessions(items, streams, { sortBy, isLearning });

    return sortBy === 'streams' && content.showViewMode
      ? this.getSessionsByStreams(items, currentView)
      : this.getSessionsByTime(
          content.items,
          currentView,
          isDateAndTimeDisplayed,
          isLearning,
          secondaryColor,
        );
  }
}
