import React, { createContext } from 'react';
import PropTypes from 'prop-types';

export const SessionsIndexMapContext = createContext({});

export function SessionsIndexMapContextProvider({ children, value }) {
  return (
    <SessionsIndexMapContext.Provider value={value}>
      {children}
    </SessionsIndexMapContext.Provider>
  );
}

export function withSessionsIndexMap(WrappedComponent) {
  class WithSessionsIndexMap extends React.Component {
    render() {
      return (
        <SessionsIndexMapContext.Consumer>
          {(sessionsIndexMap) => (
            <WrappedComponent
              {...this.props}
              sessionsIndexMap={sessionsIndexMap}
            />
          )}
        </SessionsIndexMapContext.Consumer>
      );
    }
  }

  return WithSessionsIndexMap;
}

SessionsIndexMapContextProvider.propTypes = {
  children: PropTypes.node,
  sessionsIndexMap: PropTypes.object,
};
