import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withCookies, Cookies } from 'react-cookie';
import { withTranslation } from 'react-i18next';
import cloneDeep from 'lodash/cloneDeep';
import qs from 'qs';

import Utils from '../../../../utils';
import {
  compare,
  stringifyQueryParams,
  siteTypesEnum,
  getCorrectLocale,
} from '../../../../helpers';
import api from '../../../../api/ContentService/v1';
import MA from '../../../../utils/maIntegration';

import FilterableContent from '../../../shared/FilterableContent/FilterableContent';
import SiteLayout from '../../../layouts/SiteLayout/SiteLayout';
import AgendaDaySelectors from '../../../sections/AgendaDaySelectors/AgendaDaySelectors';
import AgendaKeynoteSessions from '../../../sections/AgendaKeynoteSessions/AgendaKeynoteSessions';
import AgendaTable from '../../../sections/AgendaTable/AgendaTable';
import AgendaTimeZone from '../../../shared/AgendaTimeZone/AgendaTimeZone';
import { clearParams } from '../../../../utils/filterValidStreams';

import './SiteAgenda.scss';

const SMALL_DEVICE_BREAKPOINT = 768;

export class SiteAgenda extends Component {
  static propTypes = {
    data: PropTypes.object,
    pageConfig: PropTypes.object,
    siteAgenda: PropTypes.object,
    siteHeader: PropTypes.object,
    history: PropTypes.object,
    options: PropTypes.object,
    cookies: PropTypes.instanceOf(Cookies),
  };

  constructor(props) {
    super(props);

    this.state = {
      content: props.siteAgenda,
      isSearchFilter: false,
    };

    this.toggleViewSettings = this.toggleViewSettings.bind(this);
    this.handleAgendaViews = this.handleAgendaViews.bind(this);
  }

  changeIsSearchFilterFlag = (isSearchFilter) => {
    this.setState({
      isSearchFilter,
    });
  };

  componentDidMount() {
    const { pageConfig: { location: { search = '' } = {} } = {} } = this.props;
    const queryObject = qs.parse(clearParams(search));

    this.goToDefaultDayIfNeeded();

    // Component refactoring can take a lot of time so just added quick fix (CAAS-1199)
    if (Object.keys(this.state.content.data).length === 0) {
      this.fetchAgendaContent()
        .then((response) => response.json())
        .then((data) => {
          this.props.dispatch({ type: 'SITE_AGENDA_SUCCESS', data });

          this.setState({
            content: data,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }

    const wrongFilters = this.checkFilters(queryObject);
    if (wrongFilters) {
      this.fixWrongFilters(queryObject, wrongFilters);
    }

    this.handleAgendaViews();
    window.addEventListener('resize', this.handleAgendaViews);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleAgendaViews);
  }

  toggleViewSettings(setting, userValue) {
    const { pageConfig: { cookieSitePath } = {} } = this.props;
    const settingName = setting === 'viewTypeSearch' ? 'viewType' : setting;
    let userSelectedValue;
    const stateObj = {};

    switch (setting) {
      case 'viewType':
        userSelectedValue = this.state.viewType === 'grid' ? 'list' : 'grid';
        break;

      case 'viewTypeSearch':
        userSelectedValue = 'list';
        break;

      case 'sortBy':
        userSelectedValue = userValue;
        break;

      case 'sidebarHidden':
        userSelectedValue = !this.state.sidebarHidden;
        break;

      default:
        break;
    }

    stateObj[settingName] = userSelectedValue;

    this.props.cookies.set(settingName, userSelectedValue, {
      expires: new Date(Date.now() + 86400000), // 24 * 60 * 60 * 1000
      path: cookieSitePath,
    });

    this.setState(stateObj);
  }

  handleAgendaViews() {
    const {
      data: {
        streams,
        defaultViewType = '',
        showGridViewToggle,
        showFiltersMenu,
      } = {},
    } = this.state.content;
    const isMobile = window.innerWidth < SMALL_DEVICE_BREAKPOINT;

    const userSelectedView = this.props.cookies.get('viewType');
    const userSelectedSortBy = this.props.cookies.get('sortBy');
    const userSelectedHideSidebar = this.props.cookies.get('sidebarHidden');
    const isGridViewAvailable =
      !isMobile && showGridViewToggle && streams.length > 1;
    const viewType =
      isMobile || (!isGridViewAvailable && defaultViewType === 'GRID')
        ? 'list'
        : userSelectedView !== undefined
        ? userSelectedView
        : defaultViewType.toLowerCase();
    const sortBy =
      !isMobile && userSelectedSortBy ? userSelectedSortBy : 'time';
    const sidebarHidden =
      userSelectedHideSidebar !== undefined
        ? userSelectedHideSidebar === 'true'
        : !showFiltersMenu;

    this.setState({
      isGridViewAvailable,
      viewType,
      sortBy,
      sidebarHidden,
    });
  }

  goToDefaultDayIfNeeded() {
    const {
      siteAgenda: { data: { selectedDay: defaultDay } = {} } = {},
      match: { params: { dayNumber } = {} } = {},
    } = this.props;

    if (typeof dayNumber === 'undefined') {
      this.props.history.replace(defaultDay + '/');
    }
  }

  getHeading(selectedDay, allDates, locale = 'en', extendedSessionsList = {}) {
    const { items: sessionItems = [] } = extendedSessionsList;
    const { isSearchFilter } = this.state;
    const day = allDates[selectedDay - 1];
    const data =
      day && day.date
        ? Utils.formattedTime(day.date, 'dddd, D MMMM YYYY', locale)
        : false;
    const name = day && day.name;

    return isSearchFilter ? (
      <h1 className="c-site-agenda__heading-search-result">
        {`${sessionItems.length} matches found`}
      </h1>
    ) : (
      <h1 className="c-site-agenda__heading fc-secondary">
        <span className="c-site-agenda__heading-name">{name}</span>
        {data && <span className="c-site-agenda__heading-data">{data}</span>}
        <AgendaTimeZone />
      </h1>
    );
  }

  setTwelveTimeFormat(agendaItems, locale) {
    function setTime(e) {
      // eslint-disable-next-line prefer-regex-literals
      if (new RegExp(/^\d+:\d+$/).test(e.startTime)) {
        const momentLocale = getCorrectLocale(locale);

        e._startTime = e.startTime;
        e.startTime = moment(e.startTime, 'HH:mm')
          .locale(momentLocale)
          .format('h:mma');
        e._endTime = e.endTime;
        e.endTime = moment(e.endTime, ['HH:mm'])
          .locale(momentLocale)
          .format('h:mma');
      }
    }

    agendaItems.forEach((item) => {
      if (item.type === 'stream_group') {
        item.streams.forEach((stream) => {
          stream.streamItems.forEach((el) => {
            setTime(el);
          });
        });
      } else {
        setTime(item);
      }
    });
  }

  getPrimaryFilter() {
    const { t, i18n: { language } = {} } = this.props;
    const {
      content: {
        data: {
          showStreamFilters,
          showFormatFilters,
          streams,
          formats,
          topics,
          labels,
          allDates,
        } = {},
      },
      isSearchFilter,
    } = this.state;

    const groups = [];

    if (
      labels &&
      labels.filter(
        (label) => label.name === 'Premium' || label.name === 'Free',
      ).length > 1
    ) {
      groups.push({
        name: 'freeLabel',
        title: t('event.agenda.filter.labels.free'),
        items: labels
          .filter(
            (label) => label.name !== 'Keynote' && label.name !== 'Highlight',
          )
          .sort((a, b) => compare(a.name, b.name))
          .map((item) => ({
            name: item.name,
            count: item.count,
          })),
      });
    }

    showStreamFilters &&
      groups.push({
        name: 'stream',
        title: t('event.agenda.streams'),
        items:
          streams.length === 1 && streams[0].name === 'Main agenda'
            ? []
            : streams
                .sort((a, b) => compare(a.name, b.name))
                .map((item) => ({
                  name: item.name,
                  filterId: item.filterId.toString(),
                  count: item.count,
                  color: item.colorIndex,
                  logo: item.logo || null,
                })),
      });

    showFormatFilters &&
      formats.length > 1 &&
      groups.push({
        name: 'format',
        title: t('event.agenda.formats'),
        items: formats
          .sort((a, b) => compare(a.name, b.name))
          .map((item) => ({
            name: item.name,
            count: item.count,
          })),
      });

    isSearchFilter &&
      groups.push({
        name: 'day',
        title: t('event.agenda.tabs'),
        items: allDates
          .sort((a, b) => compare(a.date, b.date))
          .map((item = {}) => ({
            name: item.date
              ? Utils.formattedTime(item.date, 'MMM D', language)
              : item.name,
            filterId: item.dayNumber?.toString(),
          })),
      });

    groups.push({
      name: 'topic',
      title: t('event.agenda.topics'),
      items: topics
        .sort((a, b) => compare(a.name, b.name))
        .map((item) => ({
          name: item.name,
          count: item.count,
        })),
    });

    return groups.filter((group) => group.items.length > 0);
  }

  fetchAgendaContent(filters) {
    const {
      pageConfig: { siteId, location, siteTypePath, isPreview } = {},
      options: { data: { locales: { primary: primaryLocale } = {} } = {} } = {},
      i18n: { language } = {},
    } = this.props;

    let agendaDay = location.pathname.replace(/\/+$/, '').split('/').pop();
    if (agendaDay.toLowerCase() === 'agenda') {
      agendaDay = '';
    }

    const search = stringifyQueryParams(filters) || location.search;
    const isPrimaryLocaleInSearch = search.includes('primaryLocale');
    let queryParams = `?primaryLocale=${primaryLocale}`;
    if (search) {
      queryParams = isPrimaryLocaleInSearch
        ? `${search}`
        : `${search}&primaryLocale=${primaryLocale}`;
    }

    return fetch(
      api.fetchAgenda({ siteTypePath, siteId, queryParams, agendaDay }),
      {
        headers: {
          'Accept-Language': language,
          'Published-State': isPreview ? 'Draft' : 'Published',
        },
      },
    );
  }

  async fetchContent(filters) {
    const { siteAgenda: { data: { streams, formats, topics } = {} } = {} } =
      this.props;

    const wrongFilters = this.checkFilters(filters);

    if (wrongFilters) {
      this.fixWrongFilters(filters, wrongFilters);
      return;
    }

    const response = await this.fetchAgendaContent(filters).then((response) =>
      response.json(),
    );

    response &&
      response.data &&
      this.setState({
        content: response,
      });

    const stream = this.getData(streams, filters.stream, true);
    const format = this.getData(formats, filters.format);
    const topic = this.getData(topics, filters.topic);

    MA.sendMAAgendaModel({
      agendaStreamList: stream,
      agendaFormatList: format,
      agendaTopicList: topic,
    });

    return response;
  }

  fixWrongFilters(filters, wrongFilters) {
    Object.keys(filters).forEach((key) => {
      if (wrongFilters[key]) {
        filters[key] = (
          Array.isArray(filters[key]) ? filters[key] : [filters[key]]
        ).filter((x) => !wrongFilters[key].includes(x));
      }
    });
    return this.fetchContent(filters);
  }

  checkFilters(agendaData) {
    const { content: { data: { streams, formats, topics } = {} } = {} } =
      this.state;

    const stream = this.getData(streams, agendaData.stream, true);
    const format = this.getData(formats, agendaData.format);
    const topic = this.getData(topics, agendaData.topic);

    if (stream.wrong || format.wrong || topic.wrong) {
      return {
        stream: stream.wrong,
        format: format.wrong,
        topic: topic.wrong,
      };
    }
  }

  getData(list, filterData, isStreams) {
    if (!filterData) return {};

    const filterDataArray = Array.isArray(filterData)
      ? filterData
      : [filterData];
    let wrongIds = [];
    let filterObj = {};
    const filterList = [];

    if (isStreams) {
      wrongIds = cloneDeep(filterDataArray);
      filterDataArray.forEach((item) => {
        filterObj = list.find((el) => el.filterId === +item);
        if (filterObj) {
          filterList.push(filterObj.name);
          wrongIds.splice(wrongIds.indexOf(filterObj.filterId), 1);
        }
      });

      return wrongIds.length ? { wrong: wrongIds } : filterList;
    }

    filterDataArray.forEach((item) => {
      filterObj = list.find((el) => el.name === item);

      if (filterObj) {
        filterList.push(item);
      } else {
        wrongIds.push(item);
      }
    });

    return wrongIds.length ? { wrong: wrongIds } : filterList;
  }

  render() {
    const {
      i18n: { language } = {},
      t,
      pageConfig,
      siteHeader: { data: { fullScreen } = {} } = {},
    } = this.props;

    let {
      data: {
        defaultViewType,
        selectedDay,
        allDates = [],
        agendaItems,
        streams,
        showKeySessions,
        timeFormat,
        keySessions,
        type,
        name,
      } = {},
    } = this.state.content;

    if (typeof selectedDay === 'undefined' || allDates.length === 0) {
      return null;
    }

    const { data: { title: eventTitle } = {} } = this.props.siteHeader;
    const { viewType, sortBy, sidebarHidden, isGridViewAvailable } = this.state;

    const { COURSE } = siteTypesEnum();
    const isLearning = pageConfig.siteType === COURSE;
    const currentDayIndex = selectedDay - 1;
    const currentDay = allDates[currentDayIndex];

    let currentFormattedDay, selectedFormattedDay;
    if (currentDay && currentDay.date) {
      currentFormattedDay = Utils.formattedTime(
        currentDay.date,
        'dddd, D MMMM YYYY',
        language,
      );
      selectedFormattedDay = Utils.formattedTime(
        currentDay.date,
        'MMM D',
        language,
      );
    } else {
      currentFormattedDay = currentDay.name;
      selectedFormattedDay = currentDay.name;
    }

    agendaItems = Utils.highlights(agendaItems);

    if (timeFormat === 'TWELVE') {
      this.setTwelveTimeFormat(agendaItems, language);
    }
    const metaContent = t('event.agenda.day-x-of-event', {
      0: currentDay.dayNumber,
      1: eventTitle,
    });
    const listIsExpanded = defaultViewType === 'EXPANDED_LIST';
    const extendedSessionsList = Utils.getSessions(agendaItems, streams, {
      viewType,
      sortBy,
      isLearning,
    });
    const agendaName = name || t('event.agenda');
    const helmetObject = {
      title: `${agendaName} - ${currentDay.name} | ${eventTitle}`,
      description: `${currentDay.name}. ${metaContent}`,
    };

    return (
      <SiteLayout helmet={helmetObject}>
        <AgendaDaySelectors fluid={fullScreen} />
        {showKeySessions && (
          <AgendaKeynoteSessions
            agendaItems={agendaItems}
            streams={streams}
            keySessions={keySessions}
          />
        )}
        <FilterableContent
          fluid={fullScreen}
          showTopBar
          mode="agenda"
          allowSearch
          sidebarHidden={sidebarHidden}
          showViewSwitcher={isGridViewAvailable}
          viewType={viewType}
          sortBy={sortBy}
          toggleViewSettings={this.toggleViewSettings}
          primaryFilter={this.getPrimaryFilter()}
          heading={this.getHeading(
            selectedDay,
            allDates,
            language,
            extendedSessionsList,
          )}
          selectedDay={selectedFormattedDay}
          onUpdate={(filters) => this.fetchContent(filters)}
          showViewMode={extendedSessionsList.showViewMode}
          changeIsSearchFilterFlag={this.changeIsSearchFilterFlag}
        >
          <AgendaTable
            items={agendaItems}
            streams={streams}
            currentView={viewType}
            type={type}
            listIsExpanded={listIsExpanded}
            showGridView={isGridViewAvailable}
            currentDayIndex={currentDayIndex}
            currentDay={currentFormattedDay}
            agendaDates={allDates}
            extendedSessionsList={extendedSessionsList}
            locale={language}
            t={t}
            keySessions={keySessions}
            isLearning={isLearning}
          />
        </FilterableContent>
      </SiteLayout>
    );
  }
}

function mapStateToProps(state) {
  return {
    siteHeader: state.siteHeader,
    siteAgenda: state.siteAgenda,
    pageConfig: state.pageConfig,
    options: state.options,
  };
}

export default withTranslation()(
  connect(mapStateToProps)(withRouter(withCookies(SiteAgenda))),
);
