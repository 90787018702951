import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AgendaStreamItem from '../AgendaStreamItem/AgendaStreamItem';

import './AgendaStream.scss';
import { withTranslation } from 'react-i18next';

export class AgendaStream extends Component {
  constructor(props) {
    super(props);
    this.streamRef = React.createRef();
    this.checkStreamHeight = this.checkStreamHeight.bind(this);
  }

  getStreamItems(items) {
    const scaleFactor = this.props.scaleFactor;
    const itemsWithHeights = [];

    if (items.length) {
      items.forEach((item) => {
        itemsWithHeights.push({
          itemContent: item,
          itemHeight: item.duration * scaleFactor - 5,
        });
      });
    }

    return itemsWithHeights;
  }

  checkStreamHeight(expandedItemHeight) {
    const streamNode = this.streamRef.current;

    if (streamNode.offsetHeight < expandedItemHeight) {
      streamNode.style.height = expandedItemHeight + 5 + 'px';
    } else {
      streamNode.style.height = 'auto';
    }

    this.props.checkGroupHeight && this.props.checkGroupHeight();
  }

  render() {
    const { stream, scaleFactor } = this.props;
    const streamRef = this.streamRef;
    const itemsList = this.getStreamItems(stream.streamItems);

    return (
      <div className="c-agenda-stream" ref={this.streamRef}>
        <div className="agenda-stream-content-wrapper">
          {itemsList.map((item, index) => {
            return (
              <AgendaStreamItem
                streamId={stream.streamId}
                key={index}
                content={item}
                streamRef={streamRef}
                streamIsFree={stream.isFree}
                scaleFactor={scaleFactor}
                clickSessionHandler={(sessionId, streamId) =>
                  this.props.clickSessionHandler(sessionId, streamId)
                }
                checkStreamHeight={this.checkStreamHeight}
              />
            );
          })}
        </div>
      </div>
    );
  }
}

AgendaStream.propTypes = {
  stream: PropTypes.object,
  scaleFactor: PropTypes.number,
  clickSessionHandler: PropTypes.func.isRequired,
  checkGroupHeight: PropTypes.func.isRequired,
};

export default withTranslation()(AgendaStream);
