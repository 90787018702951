import React from 'react';
import PropTypes from 'prop-types';

export function ArrowButton(props) {
  const { className, onClick, type } = props;
  const iconName =
    type === 'next' ? 'keyboard_arrow_right' : 'keyboard_arrow_left';

  return (
    <button type="button" className={className} onClick={onClick}>
      <i className="material-icons">{iconName}</i>
    </button>
  );
}

ArrowButton.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default ArrowButton;
