import React from 'react';
import PropTypes from 'prop-types';

import { renderHTMLString } from '../../../../../helpers';

import './AgendaSpeakers.scss';

export default function AgendaSpeakers(props) {
  const { speakers = [], agendaType = 'list' } = props;

  return (
    <div className="row">
      <ul
        className={`col-xs-12 agenda-item-speakers agenda-item-speakers--${agendaType}`}
      >
        {speakers.map((item, index) => {
          const { title, jobTitle = '', company = '' } = item;
          return (
            <li key={index}>
              <strong className="speaker-name">
                {renderHTMLString(title)}
              </strong>
              {jobTitle.length > 0 && (
                <span className="speaker-job-title">
                  {' - '}
                  {renderHTMLString(jobTitle)}
                </span>
              )}
              {company.length > 0 && (
                <span className="speaker-job-company">
                  {', '}
                  {renderHTMLString(company)}
                </span>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
}

AgendaSpeakers.propTypes = {
  speakers: PropTypes.array,
  agendaType: PropTypes.string,
};
