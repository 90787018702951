import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withSessionsIndexMap } from '../../context';

import { renderHTMLString } from '../../../../../helpers';

import AgendaSpeakers from '../../AgendaCommonSections/AgendaSpeakers/AgendaSpeakers';
import AgendaTimeStatus from '../../../../shared/AgendaTimeStatus/AgendaTimeStatus';
import classNames from 'classnames';

import './AgendaStreamItem.scss';
import { getSessionIndexId } from '../../../../../utils/getSessions';
import AgendaCustomLabel from '../../components/AgendaCustomLabel';

const SHORTEST_ITEM_HEIGHT = 50;

export class AgendaStreamItem extends Component {
  constructor(props) {
    super(props);

    this.streamItemRef = React.createRef();
    this.state = {
      itemWidth: 'auto',
      itemZIndex: 1,
      specificItemClass:
        this.props.content.itemHeight <= SHORTEST_ITEM_HEIGHT
          ? 'shortest-agenda-item'
          : '',
    };

    this.getItemStyles = this.getItemStyles.bind(this);
    this.handleSessionClick = this.handleSessionClick.bind(this);
    this.checkForExpandedItem = this.checkForExpandedItem.bind(this);
  }

  componentDidMount() {
    this.getItemStyles();
    window.addEventListener('resize', this.getItemStyles);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.getItemStyles);
  }

  componentDidUpdate() {
    // TODO: fix
    // this.getItemStyles();
  }

  checkForExpandedItem(event) {
    if (
      this.state.itemTruncated &&
      this.props.content.itemContent.enabled &&
      this.props.checkStreamHeight &&
      event.currentTarget.childNodes.length
    ) {
      const expandedHeight = event.currentTarget.childNodes[0].offsetHeight;

      this.props.checkStreamHeight(
        event.currentTarget.offsetTop + expandedHeight,
      );
    }
  }

  getItemStyles() {
    const {
      content: { itemContent, itemHeight },
    } = this.props;
    const streamItemRef = this.streamItemRef.current;

    if (streamItemRef) {
      const itemContentWrapperHeight =
        itemContent.type === 'space'
          ? streamItemRef.clientHeight
          : streamItemRef.children[0].clientHeight;
      let crossStreamItemWidth = 'auto';
      let crossStreamItemZIndex = 1;
      if (itemContent.type !== 'space' && itemContent.crossedByStreams) {
        crossStreamItemWidth =
          streamItemRef.parentElement.clientWidth *
            itemContent.crossedByStreams +
          10 * (itemContent.crossedByStreams - 1) +
          'px';
        crossStreamItemZIndex = itemContent.crossedByStreams;
      }

      this.setState({
        itemWidth: crossStreamItemWidth,
        itemZIndex: crossStreamItemZIndex,
        itemTruncated: itemContentWrapperHeight + 5 > itemHeight,
        specificItemClass:
          itemHeight <= SHORTEST_ITEM_HEIGHT ? 'shortest-agenda-item' : '',
      });
    }
  }

  handleSessionClick() {
    const {
      streamId,
      sessionsIndexMap,
      content: {
        itemContent: { type, enabled },
      },
      content,
    } = this.props;
    const sessionIndex =
      sessionsIndexMap[getSessionIndexId(content.itemContent, streamId)];

    if (
      type !== 'space' &&
      enabled &&
      this.props.clickSessionHandler &&
      typeof this.props.clickSessionHandler === 'function' &&
      sessionIndex !== undefined
    ) {
      this.props.clickSessionHandler(sessionIndex);
    }
  }

  render() {
    const {
      content: { itemContent: content, itemHeight } = {},
      scaleFactor,
      t,
    } = this.props;
    const { itemWidth, itemZIndex, itemTruncated, specificItemClass } =
      this.state;
    const isSpace = content.type === 'space';
    const isFreeItem =
      !this.props.streamIsFree &&
      content.labels &&
      content.labels.indexOf('Free') >= 0;
    const componentClassNames = classNames({
      [`
        c-agenda-stream-item
        c-agenda-stream-item-${content.type}
        c-agenda-stream-item-${content.type}--duration-${content.duration}
        c-agenda-stream-item-${content.type}--scale-factor-${scaleFactor}
      `]: true,
      disabled: !content.enabled,
      truncated: itemTruncated,
      [specificItemClass]: specificItemClass,
    });

    return (
      <div
        className={componentClassNames}
        style={{ height: itemHeight, width: itemWidth, zIndex: itemZIndex }}
        ref={this.streamItemRef}
        onClick={this.handleSessionClick}
        onMouseEnter={this.checkForExpandedItem}
        onMouseLeave={this.checkForExpandedItem}
      >
        {!isSpace && (
          <div className="c-agenda-stream-item-inner">
            <div className="agenda-item-heading">
              <div className="agenda-item-time">
                <AgendaTimeStatus session={content} />
                {isFreeItem && (
                  <span className="agenda-item-free-label">
                    {t('event.agenda.filter.label.free')}
                  </span>
                )}
                {content.customLabel?.checked && (
                  <AgendaCustomLabel labelConfig={content.customLabel} />
                )}
              </div>
              <div className="agenda-item-title">
                {renderHTMLString(content.title)}
              </div>
            </div>
            {content.showDescription &&
              content.description &&
              content.description.length > 0 && (
                <div className="row agenda-item-description">
                  {renderHTMLString(content.description)}
                </div>
              )}
            {content.showSpeakers &&
              content.speakers &&
              content.speakers.length > 0 && (
                <AgendaSpeakers agendaType="grid" speakers={content.speakers} />
              )}
          </div>
        )}
      </div>
    );
  }
}

AgendaStreamItem.propTypes = {
  content: PropTypes.object.isRequired,
  sessionsIndexMap: PropTypes.object,
  streamIsFree: PropTypes.bool,
  streamId: PropTypes.string,
  scaleFactor: PropTypes.number,
  clickSessionHandler: PropTypes.func.isRequired,
  checkStreamHeight: PropTypes.func.isRequired,
  t: PropTypes.func,
};

export default withTranslation()(withSessionsIndexMap(AgendaStreamItem));
